const corporateIdentityDefaultFolderData = {
  logo: {
    image: {
      url: 'https://axyqwmwryo.cloudimg.io/v7/https://cdn.webmag.io/webmag/snippets/default/logo_default.png',
      width: null,
      height: null,
      focalpoint: {
        x: 50,
        y: 50,
      },
    },
  },
  favicon: {
    image: {
      url: null,
    },
  },
  _customColor: [
    '#0088d1',
    '',
    '',
    '',
  ],
  defaultFonts: {
    font1: {
      font: 'Verdana',
      type: 'system',
    },
    font2: {},
  },
  enableExtendTypo: false,
  extendTypo: {
    h1: {
      fontFamily: 'Open Sans',
      screen: {
        fontSize: '2.4rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
      tablet: {
        fontSize: '2.0rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
      mobile: {
        fontSize: '2.0rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
    },
    h2: {
      fontFamily: 'Open Sans',
      screen: {
        fontSize: '2rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
      tablet: {
        fontSize: '1.8rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
      mobile: {
        fontSize: '1.8rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
    },
    h3: {
      fontFamily: 'Open Sans',
      screen: {
        fontSize: '1.5rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
      tablet: {
        fontSize: '1.3rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
      mobile: {
        fontSize: '1.3rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
    },
    h4: {
      fontFamily: 'Open Sans',
      screen: {
        fontSize: '1.17rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
      tablet: {
        fontSize: '1.17rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
      mobile: {
        fontSize: '1.17rem',
        lineHeight: '1.2',
        fontWeight: '600',
        letterSpacing: '0px',
      },
    },
    h5: {
      fontFamily: 'Open Sans',
      screen: {
        fontSize: '0.83rem',
        lineHeight: '1.2',
        fontWeight: '400',
        letterSpacing: '0px',
      },
      tablet: {
        fontSize: '0.83rem',
        lineHeight: '1.2',
        fontWeight: '400',
        letterSpacing: '0px',
      },
      mobile: {
        fontSize: '0.83rem',
        lineHeight: '1.2',
        fontWeight: '400',
        letterSpacing: '0px',
      },
    },
    h6: {
      fontFamily: 'Open Sans',
      screen: {
        fontSize: '0.67rem',
        lineHeight: '1.2',
        fontWeight: '400',
        letterSpacing: '0px',
      },
      tablet: {
        fontSize: '0.67rem',
        lineHeight: '1.2',
        fontWeight: '400',
        letterSpacing: '0px',
      },
      mobile: {
        fontSize: '0.67rem',
        lineHeight: '1.2',
        fontWeight: '400',
        letterSpacing: '0px',
      },
    },
    p: {
      fontFamily: 'Open Sans',
      screen: {
        fontSize: '1rem',
        lineHeight: '1.5',
        fontWeight: '400',
        letterSpacing: '0px',
      },
      tablet: {
        fontSize: '1rem',
        lineHeight: '1.5',
        fontWeight: '400',
        letterSpacing: '0px',
      },
      mobile: {
        fontSize: '1rem',
        lineHeight: '1.5',
        fontWeight: '400',
        letterSpacing: '0px',
      },
    },
  },
  buttons: {
    layout: {
      buttonShape: 'round',
      buttonStyle: 'narrow',
    },
    styleObject: {
      borderRadius: '0.25rem',
      padding: '0.3125rem 1rem',
    },
  },
};

export default corporateIdentityDefaultFolderData;
